
body {
  margin: 0;
  font-family: "Montserrat-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {
  width: 6px
}

body::-webkit-scrollbar-track {
  background: #F5F8FF
}

body::-webkit-scrollbar-thumb {
  background: #0063F7
}

body::-webkit-scrollbar-thumb:hover {
  background: #004FC4
}

code {
  font-family: "Montserrat-Regular", sans-serif;
}

input:-webkit-autofill { box-shadow: 0 0 0 1000px #fff inset; background-color: #fff !important}


input:-webkit-autofill {
  -webkit-box-shadow:0 0 0 50px white inset; /* Change the color to your own background color */
  -webkit-text-fill-color: #333;
}

input:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0 50px white inset;/*your box-shadow*/
  -webkit-text-fill-color: #333;
}


/*preloader*/
.preloader {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #fff;
  z-index: 1001;
}
.preloader--hide {
  display: none;
}
.preloader__row {
  position: relative;
  top: 50%;
  left: 50%;
  width: 70px;
  height: 70px;
  margin-top: -35px;
  margin-left: -35px;
  text-align: center;
  animation: preloader-rotate 2s infinite linear;
}
.preloader__item {
  position: absolute;
  display: flex;
  justify-content: center;
  top: 0;
  background-color: #0076FF;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  animation: preloader-bounce 2s infinite ease-in-out;
}
.preloader__item:last-child {
  top: auto;
  bottom: 0;
  animation-delay: -1s;
}
.preloader__item.first svg {
  transform: rotate(180deg);
}
@keyframes preloader-rotate {
  100% {
    transform: rotate(360deg);
  }
}
@keyframes preloader-bounce {
  0%,
  100% {
    transform: scale(0);
  }

  50% {
    transform: scale(1);
  }
}
.loaded_hiding .preloader {
  transition: 0.3s opacity;
  opacity: 0;
}
/*preloader*/



